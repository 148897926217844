import React, { useState, useEffect } from 'react';
import { Dialog, Classes, Button, FormGroup, InputGroup, TextArea, Spinner } from '@blueprintjs/core';
import { DateInput } from "@blueprintjs/datetime";
import CountrySelect from '../Country/CountrySelect'
import LanguageSelect from '../Language/LanguageSelect'
import API from '../Utilities/API';

export default function (props) {

    const [loading, setLoading] = useState(true);

    const [title, setTitle] = useState(props.data?.data?.title || '')
    const [link, setLink] = useState(props.data?.data?.url || '');
    const [text, setText] = useState(props.data?.data?.description || '');
    const [date, setDate] = useState(new Date().toISOString());
    const [country, setCountry] = useState(null);
    const [language, setLanguage] = useState(null);

    useEffect(() => {
        if (loading == true) {
            API.fetchExternal(link).then((result) => {
                setTitle(result.data.Data.result.title || title);
                setText(result.data.Data.result.text || text);
                setLoading(false);
            });
        }
    }, [loading])

    return (
        <>

            <Dialog
                isOpen={true}
                onClose={() => {
                    props.onClose && props.onClose();
                }}
                autoFocus={true}
                enforceFocus={true}
                usePortal={true}
                className={Classes.DARK}
                style={{ width: 940 }}
                inheritDarkTheme={true}
                portalClassName={Classes.DARK}
            >
                <div className={Classes.DIALOG_BODY + ' ' + Classes.DARK}>
                    {loading ? <Spinner /> : (
                        <>

                            <FormGroup label="Link" fill>
                                <InputGroup placeholder="Link" value={link} onChange={(e) => { setLink(e.target.value) }} fill />
                            </FormGroup>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                gap: 10
                            }}>
                                <div style={{
                                    flexGrow: 4
                                }}>
                                    <FormGroup label="Title" fill>
                                        <InputGroup placeholder="Title" value={title} onChange={(e) => { setTitle(e.target.value) }} fill />
                                    </FormGroup>
                                </div>
                                <div style={{
                                    flexGrow: 1
                                }}>
                                    <FormGroup label="Country" fill>
                                        <CountrySelect
                                            onChange={(data) => {
                                                setCountry(data);
                                            }}
                                            onReset={() => {
                                                setCountry(null)
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                                <div style={{
                                    flexGrow: 1
                                }}>
                                    <FormGroup label="Language" fill>
                                        <LanguageSelect
                                            onChange={(data) => {
                                                setLanguage(data);
                                            }}
                                            onReset={() => {
                                                setLanguage(null)
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </div>


                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%'
                            }}>
                                <div style={{
                                    flexGrow: 1
                                }}>
                                    <FormGroup label="Date / Time" fill>
                                        <DateInput
                                            fill
                                            value={date}
                                            todayButtonText='Today'
                                            formatDate={date => date.toLocaleString()}
                                            onChange={(date) => setDate(date)}
                                            parseDate={str => new Date(str)}
                                            timePrecision='second'
                                        />
                                    </FormGroup>
                                </div>

                            </div>

                            <FormGroup label="Text" fill>
                                <TextArea placeholder="Text" value={text} onChange={(e) => { setText(e.target.value) }} fill rows={30} />
                            </FormGroup>
                        </>
                    )}

                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button intent={'primary'} icon={'upload'} onClick={() => {
                            if (!country || !country.id || !link || !link.length || !date || !date.length || !title || !title.length || !language || !language.shortcode) {
                                alert('All fields are required.');
                                return false;
                            }
                            API.ExternalIntelligence({
                                link,
                                title,
                                text,
                                date,
                                country,
                                language,
                                imageLink: props.data?.data?.image?.url,
                                case_id: props.data?.case_id
                            }).then((response) => {
                                setLoading(true);
                                if (response.data?.Data) {
                                    API.SetCase([response.data.Data], { id: props.data.case_id }).then(() => {
                                        setLoading(false);
                                        props.onSave && props.onSave();
                                        props.onClose && props.onClose();
                                    });
                                } else {
                                    setLoading(false);
                                    props.onClose && props.onClose();
                                }
                            })
                        }}>Save</Button>
                        <Button onClick={() => {
                            props.onClose && props.onClose();
                        }}>Close</Button>
                    </div>
                </div>

            </Dialog>

        </>
    )

}