import React, { useEffect, useState, useRef } from 'react';

import { Button, ControlGroup, Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2';

import API from '../Utilities/API';
import { useOnScreen } from '../../Hooks/OnScreen';

export default function CaseTitle(props) {

    const [loaded, setLoaded] = useState(props.cases || (window.nd.cases && window.nd.cases[props.group_id]) ? true : false);
    const [caso, setCaso] = useState(props.cases ? props.cases : (window.nd.cases && window.nd.cases[props.group_id]));

    const elementRef = useRef(null);
    const isOnScreen = useOnScreen(elementRef);

    useEffect(() => {
        if (!loaded && isOnScreen) loadData();
    }, [loaded, isOnScreen]);

    const loadData = () => {
        API.GroupCase(props.group_id)
            .catch((e) => {
                console.log(e)
            })
            .then((response) => {
                if (!window.nd.cases) window.nd.cases = {};
                if (!response || !response.data || !response.data.Data) return false;
                window.nd.cases[props.group_id] = response.data.Data;
                setLoaded(true);
                setCaso(response.data.Data);
            })
    }

    const renderSingleCase = () => {
        return (
            <ControlGroup style={{ display: 'inline' }}>
                <Button
                    small
                    active
                    onClick={() => {
                        if (props.onClick) props.onClick(caso[0]);
                    }}
                >
                    <span className={'case-title'}>{caso[0].title}</span>
                </Button>
                {props.removeFromCase ? (
                    <Button small active icon={'cross'} onClick={() => {
                        props.removeFromCase(props.group_id, caso[0].id);
                    }} />) : null}
            </ControlGroup>
        )
    }

    const renderCaseList = () => {
        return (
            <Menu>
                {caso.map((c) => (
                    <MenuItem
                        text={c.title}
                        label={(
                            props.removeFromCase ? <Button small icon={'cross'} onClick={() => { props.removeFromCase(props.group_id, c.id) }}></Button> : null
                        )}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (props.onClick) props.onClick(caso[0]);
                        }}
                    />
                ))}
            </Menu>
        );
    }

    const renderMultipleCases = () => {
        return (
            <Popover2
                interactionKind={'hover'}
                hoverOpenDelay={300}
                hoverCloseDelay={300}
                content={renderCaseList()}
            >
                <Button
                    active
                    small
                >{caso[0].title} ({caso.length})</Button>
            </Popover2>
        )
    }

    const renderCases = () => {
        return <>
            {caso.length > 1 ? renderMultipleCases() : renderSingleCase()}
        </>
    }

    window['reloadCaseTitle' + props.group_id] = () => {
        setLoaded(false);
        loadData();
    }

    return !loaded || !caso || caso.length == 0 || !caso[0] || !caso[0].title ? <div ref={elementRef}></div> : renderCases()
}