import axios from 'axios';
import moment from 'moment';
const API_PATH = process.env.REACT_APP_API_PATH;

const API = {

    NotificationsController: new AbortController(),

    SSE: {
        URL: '',
        ErrorCount: 0,
        init: () => {
            return false;
            const SSE = new EventSource(API.SSE.URL);
            SSE.onopen = (event) => {
                API.SSE.ErrorCount = 0;
            };
            SSE.addEventListener('server', (event) => {
                API.SSE.processMessage(JSON.parse(event.data));
            });
            SSE.onerror = (event) => {
                API.SSE.ErrorCount += 1;
                if (API.SSE.ErrorCount >= 10) {
                    setTimeout(() => {
                        API.SSE.init();
                    }, 30000);
                }
                SSE.close();
            }
        },
        processMessage: (data) => {

            if (data.type === 'group') {
                if (window['reloadGroup' + data.id]) {
                    window['reloadGroup' + data.id]();
                }
            }
        }
    },

    GetRandom: () => {
        return '0000';
        return Math.round(new Date().getTime() / 1000).toString();
    },

    Get: function (path, options = {}) {
        let full_path = `${API_PATH}${path}`;
        full_path = full_path.replace(/{TS}/, `${API.GetRandom()}`);
        return axios.get(full_path, {
            validateStatus: () => true,
            timeout: 1000 * 300,
            headers: {
                'X-API-KEY': window.nd ? window.nd['api-key'] : ''
            },
            ...options
        });
    },

    GetDirect: function (path) {
        return axios.get(path, {
            validateStatus: () => true,
            timeout: 1000 * 300
        })
    },

    Post: function (path, data) {
        let full_path = `${API_PATH}${path}`;
        full_path = full_path.replace(/{TS}/, `${API.GetRandom()}`);
        return axios.post(full_path, data, {
            validateStatus: () => true,
            timeout: 1000 * 300,
            headers: {
                'X-API-KEY': window.nd ? window.nd['api-key'] : ''
            }
        });
    },

    GetStatus: function () {
        return API.Get('/NewsDesk/GetStatus?output=json');
    },

    SaveStatus: function (data) {
        return API.Post(`/NewsDesk/SaveStatus?output=json`, data);
    },

    TypeAhead: function (q) {
        return API.Get(`/NewsDesk/TypeAhead/${encodeURIComponent(q)}?output=json`);
    },

    getFilters: function () {
        return API.Get('/NewsDesk/Filters?output=json');
    },

    getGroupsList: function (data) {
        return API.Post(`/NewsDesk/Groups?output=json`, data);
    },

    getAllKeywords: function (group_id) {
        return API.Get(`/NewsDesk/AllKeywords/${group_id}?output=json`);
    },

    getAllArticles: function (group_id) {
        return API.Get(`/NewsDesk/AllArticles/${group_id}?output=json`);
    },

    Login: function (username, password, otp) {
        let full_path = `${API_PATH}/API/RemoteLogin`;
        full_path = full_path.replace(/{TS}/, `${API.GetRandom()}`);
        return axios.post(full_path, {
            validateStatus: () => true,
            username: username,
            password: password,
            otp: otp
        });
    },

    BiometricLogin: function (username, password) {
        return API.Post(`/API/Auth/BiometricLogin`, {
            username: username,
            password: password,
            source: window.location.host
        });
    },

    VerifyBiometric: function (data) {
        data.source = window.location.host;
        return API.Post(`/API/Auth/VerifyAuthenticationRemote`, data);
    },


    StartRegistration: function () {
        return API.Post(`/API/Auth/StartRegistrationRemote`, {
            source: window.location.host
        });
    },

    VerifyRegistration: function (data) {
        data.source = window.location.host;
        return API.Post('/API/Auth/VerifyRegistrationRemote', data);
    },

    CheckHash: function (hash, data = {}) {
        return API.Post(`/API/RemoteLoginByHash/${hash}`, data);
    },

    Wiki: function (id) {
        return API.Get('/NewsDesk/Wiki/' + encodeURI(id) + '?output=json');
    },

    WikiID: function (id) {
        return API.Get(`/NewsDesk/WikiID/${id}?output=json`);
    },

    Graph: function (keywords) {
        return API.Post('/NewsDesk/Graph?output=json', { keywords: keywords });
    },

    Cases: function (closed, filter, archive, fast, alarmed) {
        return API.Post('/NewsDesk/Cases?output=json&archive=' + (archive ? '1' : '0') + '&closed=' + (closed ? '1' : '0') + '&fast=' + (fast ? '1' : '0') + '&alarmed=' + (alarmed ? '1' : '0'), {
            filter
        });
    },

    SingleCase: function (id) {
        return API.Get(`/NewsDesk/SingleCase/${id}?output=json`);
    },

    SetCase: function (as, c) {
        as.forEach((a) => {
            if (window.nd.cases) {
                delete window.nd.cases[a];

                if (typeof (window['reloadCaseTitle' + a]) === 'function') window['reloadCaseTitle' + a]();

                if (typeof (window['reloadCaseTitleIntelligence:' + a]) === 'function') window['reloadCaseTitleIntelligence:' + a]();
            }
        })
        return API.Post('/NewsDesk/SetCase?output=json', {
            articles: as,
            case_id: c.id
        });
    },

    SetIntelligenceNote: (case_id, intelligence_id, note_id) => {
        return API.Post('/NewsDesk/SetIntelligenceNote', {
            case_id,
            intelligence_id,
            note_id
        });
    },

    SetUploadNote: (case_id, file_id, note_id) => {
        return API.Post('/NewsDesk/SetUploadNote', {
            case_id,
            file_id,
            note_id
        });
    },

    CaseNews: function (id) {
        return API.Get(`/NewsDesk/CaseNews/${id}?output=json`);
    },

    CaseTravel: function (id) {
        return API.Get(`/NewsDesk/CaseTravel/${id}?output=json`);
    },

    SendMessage: function (data) {
        return API.Post('/NewsDesk/SendMessage?output=json', data);
    },

    SaveCaseText: function (case_id, text, title) {
        return API.Post(`/NewsDesk/SaveCaseText/${case_id}?output=json`, {
            text: text,
            analysis_title: title
        });
    },

    NewCase: function (data) {
        return API.Post('/NewsDesk/NewCase?output=json', data);
    },

    OG: function (url) {
        return API.Post('/NewsDesk/OG?output=json', { url: url });
    },

    AddSourceToCase: function (case_id, data) {
        return API.Post(`/NewsDesk/AddSourceToCase/${case_id}`, data);
    },

    External: function (case_id) {
        return API.Get(`/NewsDesk/GetExternal/${case_id}?output=json`);
    },

    ContentNotes: function (case_id) {
        return API.Get(`/NewsDesk/GetContentNotes/${case_id}?output=json`);
    },

    SaveContentNote: function (data) {
        return API.Post(`/NewsDesk/AddContentNote?output=json`, data);
    },

    DeleteSourceFromCase: function (case_id, datetime) {
        return API.Get(`/NewsDesk/DeleteExternal/${case_id}?datetime=${datetime}&output=json`);
    },

    DeleteIntelligenceFromCase: function (case_id, id) {
        if (typeof (window['reloadCaseTitle' + id]) === 'function') window['reloadCaseTitle' + id]();

        if (typeof (window['reloadCaseTitleIntelligence:' + id]) === 'function') window['reloadCaseTitleIntelligence:' + id]();

        return API.Get(`/NewsDesk/DeleteIntelligence/${case_id}?id=${id}&output=json`);
    },

    DeleteTravelFromCase: function (case_id, id) {
        return API.Get(`/NewsDesk/DeleteTravelFromCase/${case_id}?id=${id}&output=json`);
    },

    DeleteUploadFromCase: function (case_id, id) {
        return API.Get(`/NewsDesk/DeleteUploadFromCase/${case_id}?id=${id}&output=json`);
    },

    GroupCase: function (group_id) {
        /*
        return Promise.resolve({
            Data: []
        });
        */
        return API.Get(`/NewsDesk/GroupCase/${group_id}?output=json`);
    },

    Draft: function () {
        return API.Get('/NewsDesk/GetDraft?output=json');
    },

    SaveDraft: function (data) {
        return API.Post('/NewsDesk/SaveDraft?output=json', data);
    },

    RemoveFromCase(article_id, case_id) {
        return API.Post(`/NewsDesk/RemoveFromCase/${case_id}?output=json`, {
            article_id: article_id,
            case_id: case_id
        });
    },

    SaveExternalText(case_id, datetime, text) {
        return API.Post('/NewsDesk/SaveExternalText?output=json', {
            case_id: case_id,
            datetime: datetime,
            text
        });
    },

    SaveExternalCountry(case_id, datetime, country) {
        return API.Post('/NewsDesk/SaveExternalCountry?output=json', {
            case_id: case_id,
            datetime: datetime,
            country
        });
    },

    SaveExternalLanguage(case_id, datetime, language) {
        return API.Post('/NewsDesk/SaveExternalLanguage?output=json', {
            case_id: case_id,
            datetime: datetime,
            language
        });
    },

    SaveIntelligenceText(case_id, news_id, text) {
        return API.Post('/NewsDesk/SaveIntelligenceText?output=json', {
            case_id: case_id,
            news_id: news_id,
            text
        });
    },

    SaveUploadText(case_id, file_id, text) {
        return API.Post('/NewsDesk/SaveUploadText?output=json', {
            case_id: case_id,
            file_id: file_id,
            text
        });
    },

    Uploads: function () {
        return API.Get('/NewsDesk/Uploads?output=json');
    },

    AddUploadToCase(case_id, upload_id, title) {
        return API.Post('/NewsDesk/AddUploadToCase?output=json', { case_id, upload_id, title });
    },

    CaseUploads(case_id) {
        return API.Get(`/NewsDesk/CaseUploads/${case_id}?output=json`);
    },

    DeleteUpload(file_id) {
        return API.Get(`/NewsDesk/DeleteUpload/${file_id}`);
    },

    Comments(content_id) {
        return API.Get(`/NewsDesk/Comments/${content_id}?output=json`);
    },

    AddComment(content_id, text, type) {
        return API.Post(`/NewsDesk/AddComment/${content_id}?output=json`, { text: text, type: type });
    },

    SaveGeo(content_id, data) {
        return API.Post(`/NewsDesk/SaveGeo/${content_id}?output=json`, { data: data });
    },

    GetGeo(content_id) {
        return API.Get(`/NewsDesk/GetGeo/${content_id}?output=json`);
    },

    MapSearch(q) {
        return API.GetDirect(`https://nominatim.openstreetmap.org/search.php?q=${q}&format=json&polygon_geojson=1`);
    },

    CloseCase(id) {
        return API.Get(`/NewsDesk/CloseCase/${id}?output=json`);
    },

    OpenCase(id) {
        return API.Get(`/NewsDesk/OpenCase/${id}?output=json`);
    },

    ArchiveCase(id) {
        return API.Get(`/NewsDesk/ArchiveCase/${id}?output=json`);
    },

    UnarchiveCase(id) {
        return API.Get(`/NewsDesk/UnarchiveCase/${id}?output=json`);
    },

    Sharing(content_id) {
        return API.Get(`/NewsDesk/Sharing/${content_id}?output=json`);
    },

    Share(content_id, users) {
        return API.Post(`/NewsDesk/Share/${content_id}?output=json`, {
            content_id: content_id,
            users: users
        });
    },

    Notifications(after) {
        return API.Get(`/NewsDesk/Notifications/${after}?output=json`, {
            signal: API.NotificationsController.signal
        });
    },

    ReadNotifications(datetime) {
        API.NotificationsController.abort();
        return API.Get(`/NewsDesk/ReadNotifications/${datetime}?output=json`);
    },

    TravelSecurity(options) {
        const params = {
            start: 0,
            count: 50,
            q: '',
            ...options,
        }
        return API.Get(`/API/Security/List?start=${params.start}&q=${params.q}&count=${params.count}`);
    },

    SaveUploadTitle(case_id, upload_id, title) {
        return API.Post(`/NewsDesk/SaveUploadTitle/${upload_id}`, {
            case_id,
            upload_id,
            title
        });
    },

    Links(content_id) {
        return API.Get(`/NewsDesk/Links/${content_id}?output=json`);
    },

    NewLink(content_id, title) {
        return API.Post(`/NewsDesk/NewLink/${content_id}?output=json`, { content_id, title });
    },

    LinkToggle(link_id) {
        return API.Get(`/NewsDesk/LinkToggle/${link_id}?output=json`);
    },

    LinkDelete(link_id, title) {
        return API.Get(`/NewsDesk/LinkDelete/${link_id}?output=json`);
    },

    NotifyRemovePage(request_id) {
        return API.Get(`/NewsDesk/ClosePage/${request_id}`);
    },

    LinkViews(link_id) {
        return API.Get(`/NewsDesk/LinkViews/${link_id}?output=json`);
    },

    Keywords(article_id) {
        return API.Get(`/NewsDesk/ArticleKeywordsID/${article_id}?output=json`);
    },

    CaseIntelligenceKeywords(case_id, news_id, keyword_id, toggle) {
        return API.Post('/NewsDesk/CaseIntelligenceKeywords?output=json', {
            case_id,
            news_id,
            keyword_id,
            toggle
        });
    },

    MoveSource(type, source_id, from_case, to_case) {
        return API.Post(`/NewsDesk/MoveSource${type}`, {
            source_id,
            from_case,
            to_case
        });
    },

    SourceTree(source_id) {
        return API.Get(`/NewsDesk/SourceTree/${source_id}?output=json`);
    },

    SaveTree(page_id, tree) {
        return API.Post(`/NewsDesk/SaveTree/${page_id}`, { tree });
    },

    SavedSearches() {
        return API.Get('/NewsDesk/SavedSearches/Intelligence?output=json');
    },

    SavedSearch(id) {
        return API.Get(`/NewsDesk/SavedSearch/${id}?output=json`);
    },

    Intelligence(id, query, before, range, start) {
        if (typeof (start) === 'undefined') start = 0;
        if (!id || !id.length) return false;
        let link = '';
        if (!range || range[0] === null || range[1] === null) link = `/Intelligence?f=Inteligence&output=json&additionalData=1&` + (before ? `last_timestamp=${before}&` : '') + `&start=${start}&search_id=${id}&${query}`;
        else {
            const fr = moment(range[0]).format('YYYY-MM-DD') + ' - ' + moment(range[1]).format('YYYY-MM-DD');
            link = `/Intelligence?output=json&additionalData=1&search_id=${id}&${query}&start=${start}&range=${fr}`;
        }
        return API.Get(link)
    },

    IntelligenceNews(query, start, range) {
        if (typeof (start) === 'undefined') start = 0;

        let link = '';
        if (!range || range[0] === null || range[1] === null) link = `/Intelligence?f=intelligenceNews&output=json&start=${start}&additionalData=1&${query}`;
        else {
            const fr = moment(range[0]).format('YYYY-MM-DD') + ' - ' + moment(range[1]).format('YYYY-MM-DD');
            link = `/Intelligence?output=json&start=${start}&additionalData=1&${query}&range=${fr}`;
        }

        return API.Get(link);
    },

    SavedSearchNew(id, after) {
        return API.Post(`/NewsDesk/SavedSearchNew/${id}?output=json`, { after });
    },

    IntelligenceCases(id) {
        return API.Get(`/NewsDesk/GetCasesForArticle/${id}?output=json`);
    },

    fetchExternal(url) {
        return API.Post(`/NewsDesk/FetchExternal?output=json`, { url });
    },

    ExternalIntelligence(data) {
        return API.Post('/NewsDesk/ExternalIntelligence?output=json', data);
    },

    GetNotes() {
        return API.Get('/NewsDesk/Notes?output=json');
    },

    SaveNote(note) {
        return API.Post(`/NewsDesk/SaveNote?output=json`, note);
    },

    SaveNoteText(id, text) {
        return API.Post(`/NewsDesk/SaveNoteText?output=json`, {
            id,
            text
        });
    },

    DeleteNote(id) {
        return API.Get(`/NewsDesk/DeleteNote/${id}`);
    },

    DeleteContentNote(id) {
        return API.Get(`/NewsDesk/DeleteContentNote/${id}`);
    },

    CaseWiki(id) {
        return API.Get(`/NewsDesk/CaseWiki/${id}?output=json`);
    },

    WikiEdit(id) {
        return API.Get(`/NewsDesk/WikiEdit/${id}?output=json`)
    },

    DeleteComment(id) {
        return API.Get(`/NewsDesk/DeleteComment/${id}?output=json`);
    },

    IntelligenceArticle(id) {
        return API.Get(`/NewsDesk/IntelligenceArticle/${id}?output=json`);
    },

    Reports(skip = 0) {
        return API.Get(`/NewsDesk/Reports?output=json&skip=${skip}`);
    },

    Summarize(id) {
        return API.Get(`/NewsDesk/Summarize/${id}?output=json`);
    },

    SummarizeRequest(request) {
        return API.Post(`/NewsDesk/SummarizeText?output=json`, request);
    },

    Publish(data) {
        return API.Post(`/NewsDesk/Publish?output=json`, data);
    },

    UnpublishContentNote(data) {
        return API.Post(`/NewsDesk/UnpublishContentNote?output=json`, data);
    },

    Photo(query) {
        return API.Post(`/NewsDesk/Photo?output=json`, query);
    },

    AssignmentFromNote(data) {
        return API.Post('/NewsDesk/AssignmentFromNote?output=json', data);
    },

    AssignmentFromCase(id) {
        return API.Post(`/NewsDesk/AssignmentFromCase/${id}?output=json`);
    },

    RequestKeyword(keyword, description, type, country, translation, language) {
        return API.Post('/NewsDesk/RequestKeyword?output=json', { keyword, description, type, country, translation, language });
    },

    ExpandGroup(id) {
        return API.Get(`/NewsDesk/ExpandGroup/${id}?output=json`);
    },

    SaveContentFolders(case_id, content_id, folders) {
        return API.Post(`/NewsDesk/SaveContentFolders`, {
            case_id,
            content_id,
            folders
        });
    },

    LinkTitles() {
        return API.Get('/NewsDesk/LinkTitles/?output=json');
    },

    ListKeywords(start, query, countries, languages, types, categories, modifier) {
        const searchParams = new URLSearchParams();

        searchParams.append('start', start || 0);
        if (query && query.length > 0) searchParams.append('q', query);
        if (countries && countries.length > 0) countries.forEach((selected_country) => searchParams.append('country[]', selected_country));
        if (languages && languages.length > 0) languages.forEach((selected_language) => searchParams.append('language[]', selected_language));
        if (categories && categories.length > 0) categories.forEach((selected_category) => searchParams.append('category[]', selected_category));
        if (types && types.length > 0) types.forEach((selected_type) => searchParams.append('types[]', selected_type));

        const q = searchParams.toString() + `&${modifier}`;
        return API.Get(`/NewsDesk/Keywords?${q}&output=json`);

    },

    SaveIntelligenceSearch(query, title, folder) {
        return API.Post('/NewsDesk/SaveIntelligenceSearch?output=json', {
            query,
            title,
            folder
        });
    },

    NewSearchFolder(title) {
        return API.Post('/NewsDesk/NewSearchFolder?output=json', {
            title
        });
    },

    DeleteIntelligenceSearch(id) {
        return API.Get(`/NewsDesk/DeleteIntelligenceSearch/${id}`);
    },

    ToggleSearchCase(case_id, saved_search_id) {
        return API.Post('/NewsDesk/toggleSearchCase?output=json', {
            case_id,
            saved_search_id
        });
    },

    CaseSavedSearches(case_id) {
        return API.Get('/NewsDesk/CaseSavedSearches/' + case_id + '?output=json');
    },

    ToggleCaseAlarm(case_id) {
        return API.Get('/NewsDesk/ToggleCaseAlarm/' + case_id + '?output=json')
    },

    ToggleCaseVerified(case_id) {
        return API.Get('/NewsDesk/ToggleCaseVerified/' + case_id + '?output=json')
    },

    PercolateText(text, id = false) {
        return API.Post(`/NewsDesk/PercolateText${id && id.length ? '/' + id : ''}?output=json`, {
            text
        })
    },

    CaseHistory(case_id) {
        return API.Get(`/NewsDesk/CaseHistory/${case_id}?output=json`);
    },

    SaveSAML(response) {
        return API.Post('/NewsDesk/SaveSAML', response);
    },

    DB: {
        Sanctions: {
            List(query) {
                return API.Get(`/NewsDesk/Sanctions/${query}?output=json`);
            },
            ID(id) {
                return API.Get(`/NewsDesk/Sanction/${id}?output=json`);
            }
        }
    },

    AI: {

        NewRequest(text, data) {
            return API.Post('/NewsDesk/AICreateRequest?output=json', { text, data })
        },

        Requests() {
            return API.Get('/NewsDesk/AIRequests?output=json');
        },

        Request(id) {
            return API.Get(`/NewsDesk/AIRequest/${id}?output=json`);
        },

        Status(id) {
            return API.Get(`/NewsDesk/AIStatus/${id}?output=json`);
        },

        ResponseData(id, data) {
            return API.Post(`/NewsDesk/AIResponseData/${id}?output=json`, data);
        },

        Translation(text) {
            return API.Post('/NewsDesk/AITranslation?output=json', { text });
        },

        Analysis(text) {
            return API.Post('/NewsDesk/AIAnalysis?output=json', { text });
        },

        Titles(text) {
            return API.Post('/NewsDesk/AITitles?output=json', { text });
        },

        Incipit(text) {
            return API.Post('/NewsDesk/AIIncipit?output=json', { text });
        },

        Highlights(text) {
            return API.Post('/NewsDesk/AIHighlights?output=json', { text });
        },

        Contradictions(text) {
            return API.Post('/NewsDesk/AIContradictions?output=json', { text });
        },

        Website(text) {
            return API.Post('/NewsDesk/AIWebsite?output=json', { text });
        },

        ExpandSearch(text) {
            return API.Post('/NewsDesk/AIExpandSearch?output=json', { text });
        }
    }


}

export default API;