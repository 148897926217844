import React, { useState, useEffect } from 'react';

import { Button, Card, Spinner, Drawer, Position, DrawerSize, Classes } from '@blueprintjs/core';
import U from '../Utilities/Utilities';

import useClipboard from 'react-hook-clipboard'
import API from '../Utilities/API';

import Cases from '../Cases/Cases';

export default ({ data, request, closeAI, render, speed }) => {

    const [responseData, setResponseData] = useState(data);
    const [clipboard, copyToClipboard] = useClipboard();
    const [update, setUpdate] = useState(new Date().getTime());
    const [drawerCase, setDrawerCase] = useState(null);
    const [publishedNote, setPublishedNote] = useState(data.data && data.data.publishedNote);

    const time = speed ?? 10;

    let timer = null;

    useEffect(() => {
        if (!responseData.process_done || !responseData.process_done.length) {
            timer = setTimeout(() => {
                API.AI.Status(responseData.id).then((res) => {
                    setResponseData(res.data.Data);
                });
                setUpdate(update + 1);
            }, time * 1000);
        }
        return () => {
            clearTimeout(timer);
        }
    }, [update]);

    return render ? render(responseData) : (
        <>
            {drawerCase && drawerCase.id && (
                <Drawer
                    icon={'box'}
                    title={drawerCase.title}
                    autoFocus
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    enforceFocus
                    hasBackdrop
                    position={Position.RIGHT}
                    size={'100%'}
                    isOpen={true}
                    className={Classes.DARK}
                    onClose={() => {
                        setDrawerCase(null);
                    }}
                >
                    <Cases
                        popup
                        case={drawerCase}
                        key={drawerCase.id}
                        setCaseText={(text) => {

                        }}
                        reloadCases={() => {

                        }}
                        onCloseCase={() => {
                            setDrawerCase(null);
                        }}
                        showNotes
                    />
                </Drawer>
            )}
            <Card key={`SingleResponse-${responseData.id}-${update}`} style={{
                marginBottom: 20
            }}>
                <div style={{
                    display: 'flex',
                    alignContent: 'space-between',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%',
                }}>
                    <div>
                        <h5><a href="javascript: void(0)">{responseData.type}</a></h5>
                    </div>
                    {responseData.process_done && responseData.process_done.length && (
                        <div style={{
                            display: 'flex'
                        }}>
                            <Button
                                icon={clipboard === responseData.response ? 'tick' : 'clipboard'}
                                intent={clipboard === responseData.response ? 'success' : null}
                                style={{
                                    margin: 'auto'
                                }}
                                onClick={() => {
                                    copyToClipboard(responseData.response);
                                }}
                                text={clipboard === responseData.response ? 'Copied' : 'Copy'}
                            />
                            <div style={{ margin: 'auto' }}>
                                {request.data && request.data.case_id && request.data.case_id.length && (
                                    <Button icon={publishedNote ? 'tick' : 'plus'} intent={publishedNote ? 'success' : 'primary'} text={'Publish note'} onClick={() => {
                                        const text = responseData.response.split('\n');
                                        let rest = [];
                                        if (text.length > 1) {
                                            for (let i = 1; i < text.length; i++) rest.push(text[i]);
                                        }
                                        API.SaveContentNote({
                                            content_id: request.data.case_id,
                                            title: text.length > 1 ? text[0] : '',
                                            text: text.length > 1 ? rest.join('\n') : text[0],
                                            note_id: null
                                        }).then(() => {
                                            if (publishedNote) {
                                                API.SingleCase(request.data.case_id).then((response) => {
                                                    if (response.data.Data && response.data.Data.id) {
                                                        setDrawerCase(response.data.Data);
                                                        closeAI && closeAI();
                                                    }
                                                })
                                            } else {
                                                API.AI.ResponseData(responseData.id, { publishedNote: true }).then(() => {
                                                    setPublishedNote(true);
                                                    if (window.confirm('View case?')) {
                                                        API.SingleCase(request.data.case_id).then((response) => {
                                                            if (response.data.Data && response.data.Data.id) {
                                                                setDrawerCase(response.data.Data);
                                                                closeAI && closeAI();
                                                            }
                                                        })
                                                    }
                                                });
                                            }
                                        });
                                    }} />
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {responseData.process_done && responseData.process_done.length ? (
                    <>
                        <p style={{
                            whiteSpace: 'pre-line'
                        }}>{responseData.response}</p>
                        <div style={{
                            width: '100%',
                            textAlign: 'right'
                        }}>
                            <small>{U.prettyStampFull(responseData.datetime)} - {responseData.time}s</small>
                        </div>
                    </>
                ) : (
                    <>
                        <Spinner intent={'success'} value={responseData.process_started && responseData.process_started.length ? null : 0} />
                    </>
                )}
            </Card>
        </>
    )

}