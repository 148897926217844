import React, { Component } from 'react';
import { Button, Dialog, Classes, FormGroup, InputGroup, Callout, Intent } from '@blueprintjs/core';
import { startAuthentication } from '@simplewebauthn/browser';
import classNames from 'classnames';

import API from '../Utilities/API';
import Azure from '../SSO/Azure';

import {
    LOGIN_REQUEST,
    PUBLIC_CLIENT_APPLICATION,
    TOKEN_REQUEST,
    GRAPH_CONFIG
} from '../../msalConfig'

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            username: '',
            password: '',
            otp: '',
            remoteError: false
        };

        this.Login = this.Login.bind(this);
        this.BiometricLogin = this.BiometricLogin.bind(this);
    }

    Login() {
        this.setState({
            loading: true
        }, () => {
            API.Login(this.state.username, this.state.password, this.state.otp).then((response) => {
                if (response.data.error || !response.data.hash) {
                    this.setState({
                        loading: false,
                        error: true,
                        remoteError: true
                    })
                } else {
                    this.setState({
                        loading: false,
                        error: false,
                        remoteError: false
                    }, () => {
                        window.nd = {
                            'api-key': response.data.hash
                        };
                        localStorage.setItem('agenzia-api-user', JSON.stringify(response.data));
                        this.props.setUser(response.data);
                    })
                }
            }).catch(() => {
                this.setState({
                    loading: false,
                    error: true,
                    remoteError: true
                })
            });
        })
    }

    setField(field, e) {
        let s = this.state;
        s[field] = e.target.value;
        this.setState(s);
    }

    _handleKey(field, e) {
        if (e.key === 'Enter') {
            if (field === 'username') this._password.focus();
            else if (field === 'password') this._otp.focus();
            else if (field === 'otp') this.Login();
        }
    }

    BiometricLogin() {
        this.setState({
            loading: true
        }, () => {
            API.BiometricLogin(this.state.username, this.state.password).then((response) => {
                if (response.data.error) {
                    alert(response.data.error);
                    return;
                } else {
                    startAuthentication(response.data).then((result) => {
                        API.VerifyBiometric(result).then((response) => {
                            if (response.data.error || !response.data.data || response.data.data.error || !response.data.data.hash) {
                                this.setState({
                                    loading: false,
                                    error: true,
                                    remoteError: true
                                })
                            } else {
                                this.setState({
                                    loading: false,
                                    error: false,
                                    remoteError: false
                                }, () => {
                                    window.nd = {
                                        'api-key': response.data.data.hash
                                    };
                                    localStorage.setItem('agenzia-api-user', JSON.stringify(response.data.data));
                                    this.props.setUser(response.data.data);
                                })
                            }
                        });
                    });
                }
            }).catch(() => {
                this.setState({
                    loading: false,
                    error: true,
                    remoteError: true
                })
            });
        })
    }

    render() {
        const classes = classNames(
            Classes.CARD,
            Classes.ELEVATION_4
        );

        return <Dialog
            hasBackdrop
            autoFocus
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            enforceFocus
            isOpen={true}
            title={'Login'}
            className={'bp5-dark'}
            isCloseButtonShown={false}
        >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup>
                    <InputGroup placeholder="Username" autoFocus onChange={this.setField.bind(this, 'username')} onKeyUp={this._handleKey.bind(this, 'username')} value={this.state.username} />
                </FormGroup>
                <FormGroup>
                    <InputGroup placeholder="Password" onChange={this.setField.bind(this, 'password')} onKeyUp={this._handleKey.bind(this, 'password')} type={'password'} value={this.state.password} inputRef={(input) => { this._password = input }} />
                </FormGroup>
                <FormGroup>
                    <InputGroup placeholder="OTP" onChange={this.setField.bind(this, 'otp')} onKeyUp={this._handleKey.bind(this, 'otp')} type={'text'} value={this.state.otp} inputRef={(input) => { this._otp = input }} />
                </FormGroup>
                {this.state.error ? (
                    <Callout title={'Wrong credentials'} intent={Intent.DANGER}>
                        {this.state.remoteError ? 'We are unable to log you in at the current time.' : 'Check your username and password and try again.'}
                    </Callout>
                ) : null}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button loading={this.state.loading} onClick={this.Login}>OTP Login</Button>
                    <Button loading={this.state.loading} onClick={() => {
                        if (!this.state.username.length || !this.state.password.length) {
                            alert('Please enter your username and password first.');
                        } else {
                            this.BiometricLogin();
                        }
                    }}>Biometric login</Button>
                    <Button loading={this.state.loading} onClick={async () => {
                        const accounts = PUBLIC_CLIENT_APPLICATION.getActiveAccount();
                        if (accounts && accounts.length === 1) await PUBLIC_CLIENT_APPLICATION.setActiveAccount(accounts[0]);
                        else {
                            const loginResponse = await PUBLIC_CLIENT_APPLICATION.loginPopup(LOGIN_REQUEST);
                            if (loginResponse.account) {
                                await PUBLIC_CLIENT_APPLICATION.setActiveAccount(loginResponse.account);
                                window.askUnload = false;
                                window.location.href = '/?saml#saml';
                            }
                            const tokenResponse = await PUBLIC_CLIENT_APPLICATION.acquireTokenSilent(TOKEN_REQUEST);
                        }
                    }}>Azure SSO</Button>
                </div>
            </div>
        </Dialog >
    }
}

export default Login;