import React from 'react';

import API from '../Utilities/API'

import { Alignment, Button, Navbar, Collapse, NavbarGroup, NavbarHeading, NavbarDivider, Spinner, ControlGroup, PanelStack2 } from '@blueprintjs/core'
import { Drawer, Position, Classes } from '@blueprintjs/core';

import U from '../Utilities/Utilities'
import Cases from '../Cases/Cases';

import TravelListSingle from './TravelListSingle';
import TravelFull from './TravelFull';
import SimpleSearch from '../Navigation/SimpleSearch'
class TravelSecurity extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: {
                hits: []
            },
            expanded: true,
            activeCase: {
                id: ''
            },
            count: 50,
            loading: false,
            q: '',

            drawerCase: null,
            showDrawer: false
        }

        this.loadData = this.loadData.bind(this);
        this.loadMore = this.loadMore.bind(this);

        this.renderList = this.renderList.bind(this);

        this.setDrawerCase = this.setDrawerCase.bind(this);
        this.setShowDrawer = this.setShowDrawer.bind(this);
    }

    setDrawerCase(data) {
        this.setState({
            drawerCase: data
        })
    }

    setShowDrawer(data) {
        this.setState({
            showDrawer: data
        })
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.loadData();
        }
    }

    componentDidUpdate() {
        window.requestAnimationFrame(() => {
            let el = document.querySelector('button.singleGroupButton.bp5-active');
            if (el) el.scrollIntoViewIfNeeded({ block: "center" });
        });
    }

    loadMore() {
        this.setState({
            loading: true
        }, () => {
            this.loadData();
        })
    }

    loadData() {
        API.TravelSecurity({
            start: this.state.data.hits.length || 0,
            q: this.state.q
        }).then((response) => {
            if (response.data.hits.length) {
                this.setState({
                    data: {
                        hits: this.state.data.hits.concat(response.data.hits)
                    },
                    loaded: true,
                    loading: false
                })
            }
        })
    }

    setActive(entry) {
        this.setState({
            activeCase: entry,
            expanded: false
        })
    }

    renderList() {
        let date = '';
        return !this.state.loaded || !this.state.data.hits ? <Spinner /> : <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            {this.state.data.hits.map((hit) => {
                let time = hit._source.updated;
                time = U.date(time);
                let showDate = false;
                if (time != date) {
                    date = time;
                    showDate = true;
                }

                return (<>
                    {
                        showDate ? <Button
                            fill
                            minimal
                            disabled
                            large
                            icon={'calendar'}
                            style={{ color: '#FFB366', cursor: 'default', position: 'sticky', top: 0, backgroundColor: '#30404d', zIndex: 1 }}
                        > {date}</Button > : null
                    }
                    <TravelListSingle
                        data={hit}
                        isActive={this.state.activeCase && this.state.activeCase._id === hit._id}
                        setActive={this.setActive.bind(this, hit)}
                        expanded={this.state.expanded}
                    />
                </>)
            })}
            <div style={{ padding: 10 }}>
                <Button large fill loading={this.state.loading} onClick={this.loadMore}>Load more</Button>
            </div>
        </div>
    }

    render() {
        return (
            <>
                <div style={{
                    width: '100%',
                    height: 50
                }}>
                    <Navbar style={{ position: 'sticky', top: 0, paddingLeft: 0 }}>
                        <NavbarGroup>
                            <NavbarHeading>Travel Security</NavbarHeading>
                            <NavbarDivider />
                        </NavbarGroup>
                        <NavbarGroup align={Alignment.CENTER} style={{ justifyContent: 'center' }}>
                            <SimpleSearch style={{ width: 700 }} onSearch={(text) => {
                                this.setState({
                                    q: text,
                                    loaded: false,
                                    data: {
                                        hits: []
                                    },
                                    expanded: true,
                                    activeCase: {
                                        id: ''
                                    }
                                }, () => {
                                    this.loadData();
                                })
                            }} />
                        </NavbarGroup>
                    </Navbar>
                </div>
                <div style={{
                    width: '100%',
                    height: 'calc(100% - 50px)'
                }}>
                    <div style={{
                        width: this.state.expanded ? '100%' : '30%',
                        height: '100%',
                        overflow: 'hidden',
                        float: 'left'
                    }} className={'hide-header'}>
                        <PanelStack2
                            stack={[
                                {
                                    renderPanel: this.renderList,
                                    className: 'panel-groupList',
                                    title: <div style={{ width: '100%' }}>
                                        {this.state.loading ? <Spinner size={16} /> : ``}
                                        {this.props.expanded ? null : (
                                            <Button
                                                minimal
                                                icon={this.props.expanded ? 'minimize' : 'maximize'} style={{ position: 'absolute', top: 0, right: 0 }}
                                                onClick={() => {
                                                    this.setState({
                                                        activeCase: {},
                                                        expanded: true
                                                    })
                                                }}
                                            />
                                        )}
                                    </div>
                                }
                            ]}
                            className={'full-height'}
                        />
                    </div>
                    {!this.state.expanded ? (
                        <div style={{
                            width: this.state.expanded ? '0%' : '70%',
                            height: '100%',
                            overflow: 'hidden',
                            float: 'left'
                        }}>
                            <div style={{
                                width: '100%',
                                height: '100%',
                                overflowY: 'hidden',
                                float: 'left'
                            }}>
                                <TravelFull
                                    data={this.state.activeCase}
                                    setDrawerCase={this.setDrawerCase}
                                    setShowDrawer={this.setShowDrawer}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
                {this.state.showDrawer && (
                    <Drawer
                        icon={'box'}
                        title={this.state.drawerCase.title}
                        autoFocus
                        canEscapeKeyClose={false}
                        canOutsideClickClose={false}
                        enforceFocus
                        hasBackdrop
                        position={Position.RIGHT}
                        size={'100%'}
                        isOpen={true}
                        className={Classes.DARK}
                        onClose={() => {
                            this.setDrawerCase(null);
                            this.setShowDrawer(false);
                        }}
                    >
                        <Cases
                            popup
                            case={this.state.drawerCase}
                            key={this.state.drawerCase.id}
                            setCaseText={(text) => {

                            }}
                            reloadCases={() => {

                            }}
                            onCloseCase={() => {
                                this.setDrawerCase(null);
                                this.setShowDrawer(false);
                            }}
                        />
                    </Drawer>
                )}
            </>

        )
    }
}

export default TravelSecurity;