import React, { useEffect } from 'react';

import {
    PUBLIC_CLIENT_APPLICATION,
} from '../../msalConfig'

export default function Page() {

    useEffect(async () => {
        window.askUnload = false;
        localStorage.removeItem('agenzia-api-user');
        window.nd = {};
        window.askUnload = false;
        await PUBLIC_CLIENT_APPLICATION.logoutRedirect({
            onRedirectNavigate: (url) => {
                return false;
            }
        });
        window.location.href = '/';
    }, [])

    return null;
}