import React from 'react';

import API from '../Utilities/API';
import SingleSource from './SingleSource';

import { Spinner, Card, MenuItem } from '@blueprintjs/core'

import MapButton from '../Map/Button'

import EditText from './EditText';

import CasePopup from '../Cases/CasePopup';
import AddExternal from '../OG/AddExternal'

class ExternalSources extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            data: [],
            showCasePopup: false,
            moveSourceID: null,
            openIntelligence: false
        }

        this.moveSourceToCase = this.moveSourceToCase.bind(this);
        this.DeleteSource = this.DeleteExternal.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) this.loadData();
    }


    loadData() {
        API.External(this.props.case_id).then((response) => {
            this.setState({
                loaded: true,
                data: response.data.Data
            }, () => {
                this.props.setNumber(this.state.data.length);
                window.twttr.widgets.load();
            });
        })
    }

    DeleteExternal(datetime) {
        if (window.confirm('Remove source?')) {
            API.DeleteSourceFromCase(this.props.case_id, datetime).then(() => {
                this.setState({
                    loaded: false,
                    data: []
                }, () => {
                    this.props.doReload();
                })
            })
        }
    }

    moveSourceToCase(c) {
        API.MoveSource('External', this.state.moveSourceID, this.props.case_id, c.id).then((response) => {
            this.setState({
                loaded: false,
                data: []
            }, () => {
                this.loadData();
            })
        });
    }

    render() {
        return !this.state.loaded ? <Spinner /> : (
            <>
                {this.state.openIntelligence && (
                    <AddExternal
                        data={this.state.intelligenceData}
                        onClose={() => {
                            this.setState({
                                openIntelligence: false,
                                intelligenceData: null
                            })
                        }}
                        onSave={() => {
                            this.DeleteSource(this.state.intelligenceExternalID);
                            this.props.onSave && this.props.onSave();
                        }}
                    />
                )}
                {this.props.showTitle && this.state.data.length ? <h3 style={{ marginLeft: 10, marginTop: 10 }}>External sources</h3> : null}
                {this.state.data.map((c) => (
                    <>
                        <div style={{ marginBottom: 10 }}>
                            <SingleSource
                                data={{
                                    ...c.data,
                                    saved_text: c.text
                                }}
                                case_id={this.props.case_id}
                                source_id={c.datetime}
                                cancel={this.DeleteExternal.bind(this, c.datetime)}
                                folders={this.props.folders}
                                onSelectedFolderChange={(folders) => {
                                    API.SaveContentFolders(this.props.case_id, c.datetime, folders).then(() => {
                                        this.setState({
                                            loaded: false,
                                            data: []
                                        }, () => {
                                            this.loadData();
                                            this.props.onFolderChange && this.props.onFolderChange();
                                        })
                                    })
                                }}
                                move={() => {
                                    this.setState({
                                        moveSourceID: c.datetime,
                                        showCasePopup: true
                                    })
                                }}
                                buttons={
                                    <>
                                        <MenuItem
                                            icon={'git-commit'}
                                            onClick={() => {
                                                this.setState({
                                                    openIntelligence: true,
                                                    intelligenceData: c,
                                                    intelligenceExternalID: c.datetime
                                                })

                                            }}
                                            text={'Add to intelligence'}
                                        />
                                        <MapButton
                                            style={{ marginRight: 10 }}
                                            content_id={`${c.case_id}-${c.datetime}`}
                                            Save={(data) => {
                                                return API.SaveGeo(`${c.case_id}-${c.datetime}`, data);
                                            }}
                                        />
                                        <EditText text={c.text} style={{ marginRight: 10 }} Save={(text) => {
                                            API.SaveExternalText(c.case_id, c.datetime, text).then(() => {
                                                this.setState({
                                                    loaded: false,
                                                    data: []
                                                }, () => {
                                                    this.loadData();
                                                })
                                            })
                                        }} />
                                    </>
                                }
                            />
                        </div>
                        {
                            this.state.showCasePopup ? (
                                <CasePopup
                                    onShow={() => {
                                        this.setState({ showCasePopup: true })
                                    }}
                                    onHide={() => {
                                        this.setState({ showCasePopup: false })
                                    }}
                                    onSelect={(c) => {
                                        this.moveSourceToCase(c);
                                    }}
                                />
                            ) : null
                        }
                    </>
                ))}
            </>
        )
    }
}

export default ExternalSources;