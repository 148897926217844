import './App.css';
import 'react-reflex/styles.css'
import { FocusStyleManager, Spinner } from "@blueprintjs/core";
import Cookies from 'universal-cookie';
import React from 'react';
import MainPage from './Components/Pages/MainPage';
import API from './Components/Utilities/API';
import Login from './Components/Main/Login';
import Home from './Components/Pages/Home';
import UploadOverlay from './Components/Upload/Overlay';
import LoginToBL from './Components/Main/LoginToBL';

import {
    LOGIN_REQUEST,
    PUBLIC_CLIENT_APPLICATION,
    TOKEN_REQUEST,
    GRAPH_CONFIG
} from './msalConfig'

const cookies = new Cookies();

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            User: false,
            loaded: false,
            selectedPage: 'home',
            lastUpdate: 0,
            pages: [
                {
                    id: 'home',
                    title: 'Home',
                    component: 'Home'
                }
            ],
            DRAFT: {}
        }

        if (!window.nd) window.nd = {};
        this.latestPage = 'home';

        if (this.state.User !== false) {
            window.nd['api-key'] = this.state.User.hash;
        }

        if (!window.nd.cases) window.nd.cases = {};

        FocusStyleManager.onlyShowFocusOnTabs();

        this.setUser = this.setUser.bind(this);
        this.search = this.search.bind(this);

        this.addPage = this.addPage.bind(this);
        this.removePage = this.removePage.bind(this);

        this.savePages = this.savePages.bind(this);

        this.setSelectedPage = this.setSelectedPage.bind(this);
        this.LoginSAML = this.LoginSAML.bind(this);
    }

    setSelectedPage(page) {
        this.latestPage = page;
        this.savePages();
    }

    addPage(page) {
        let p = this.state.pages;
        p.push(page);
        this.setState({
            pages: p,
            selectedPage: p.id,
            lastUpdate: new Date().getTime()
        }, () => {
            this.savePages();
        });
    }

    savePages(pages) {
        //return false;
        API.SaveStatus({
            pages: pages ? pages : this.state.pages,
            selectedPage: this.latestPage
        }).then(() => {
            if (pages) this.setState({
                pages: pages
            });
        });
    }

    removePage(id, request_id) {
        this.setState({
            pages: this.state.pages.filter((page) => (page.id !== id)),
            lastUpdate: new Date().getTime()
        }, () => {
            this.savePages();
            if (request_id && request_id.length > 0) {
                API.NotifyRemovePage(request_id);
            }
        });
    }

    LoginSAML(onError) {
        const activeSAML = PUBLIC_CLIENT_APPLICATION.getActiveAccount();
        console.log('activeSAML', activeSAML);
        if (activeSAML && activeSAML.idTokenClaims) {
            API.CheckHash('saml', activeSAML).then((response) => {
                if (response.data.error) {
                    if (window.location.hash.indexOf('#saml') > -1) {
                        alert('There is no active SSO account with your credentials. Your request has been forwarded to our administration.');
                        try {
                            window.location.hash = window.location.hash.replace(/saml/, '');
                        } catch (e) {

                        }
                    }
                    onError();
                    return false;
                }
                let user = response.data;
                localStorage.setItem('agenzia-api-user', JSON.stringify(response.data));
                window.nd = {
                    'api-key': user.hash
                };
                cookies.set('hash', user.hash, { path: '/' });
                this.setState({
                    User: user
                }, () => {
                    API.GetStatus().then((response) => {
                        window.nd.User = user;
                        if (response.data.Data.pages) {
                            let st = this.state;
                            st.pages = response.data.Data.pages;
                            st.selectedPage = response.data.Data.selectedPage;
                            this.latestPage = response.data.Data.selectedPage;
                            st.loaded = true;
                            this.setState(st, () => {
                                API.SSE.init();
                            });
                        } else {
                            onError()
                        }
                    })
                });
            });
        } else {
            onError();
        }
    }

    componentDidMount() {
        window.addEventListener("beforeunload", function (event) {
            if (typeof (window.askUnload) === 'undefined' || window.askUnload === true) event.returnValue = window.confirm("Data will be lost if you leave the page, are you sure?");
            setTimeout(() => {
                window.askUnload = true;
            }, 1500);
        })
        if (this.state.User === false) {
            let user = localStorage.getItem('agenzia-api-user');
            if (user) {
                user = JSON.parse(user);
                if (user.hash && user.hash.length > 20) {
                    API.CheckHash(user.hash).then((response) => {
                        if (response.data.id === user.id) {
                            user = response.data;
                            localStorage.setItem('agenzia-api-user', JSON.stringify(response.data));
                            window.nd = {
                                'api-key': user.hash
                            };
                            cookies.set('hash', user.hash, { path: '/' });
                            this.setState({
                                User: user
                            }, () => {
                                API.GetStatus().then((response) => {
                                    window.nd.User = user;
                                    if (response.data.Data.pages) {
                                        let st = this.state;
                                        st.pages = response.data.Data.pages;
                                        st.selectedPage = response.data.Data.selectedPage;
                                        this.latestPage = response.data.Data.selectedPage;
                                        st.loaded = true;
                                        this.setState(st, () => {
                                            API.SSE.init();
                                        });
                                    } else {
                                        this.setState({
                                            loaded: true
                                        }, () => {
                                            API.SSE.init();
                                        });
                                    }
                                })
                            });
                        } else {

                            this.LoginSAML(() => {
                                this.setState({
                                    loaded: true
                                }, () => {
                                    API.SSE.init();
                                    window.askUnload = true;
                                })
                            });
                        }
                    })
                } else {

                    this.LoginSAML(() => {
                        this.setState({
                            loaded: true
                        }, () => {
                            API.SSE.init();
                            window.askUnload = true;
                        })
                    });
                }
            } else {
                this.LoginSAML(() => {
                    this.setState({
                        loaded: true
                    }, () => {
                        API.SSE.init();
                        window.askUnload = true;
                    })
                });
            }
        } else {
            this.setState({
                loaded: true
            }, () => {
                API.SSE.init();
                window.askUnload = true;
            });
        }
    }

    setUser(user) {
        this.setState({
            User: user,
            loaded: false
        }, () => {
            window.nd.User = user;
            API.GetStatus().then((response) => {
                if (response.data.Data && response.data.Data.pages) {
                    let st = this.state;
                    st.pages = response.data.Data.pages;
                    st.selectedPage = response.data.Data.selectedPage;
                    this.latestPage = response.data.Data.selectedPage;
                    st.loaded = true;
                    this.setState(st);
                } else {
                    this.setState({
                        loaded: true
                    });
                }
            })
        });
    }

    search(country, language, keywords) {
        let p = this.state.pages;
        const nid = new Date().getTime();
        p.push({
            id: nid,
            title: 'Search',
            component: <Home
                selectedCountry={country}
                selectedLanguage={language}
                selectedKeywords={keywords}
            />
        })
        this.setState({
            updateCount: this.state.updateCount + 1,
            pages: p,
            selectedPage: nid
        });
    }

    render() {
        if (!this.state.loaded) return <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spinner style={{ margin: 'auto' }} /></div>
        return this.state.User === false ? <Login setUser={this.setUser} /> : (
            <>
                <UploadOverlay />
                <MainPage
                    selectedPage={this.state.selectedPage}
                    setSelectedPage={this.setSelectedPage}
                    pages={this.state.pages}
                    removePage={this.removePage}
                    key={this.state.lastUpdate}
                    addPage={this.addPage}
                    savePages={this.savePages}
                />
                <LoginToBL />
            </>
        )
    }

}

export default App;
