import React, { useState, useEffect } from 'react';
import { Tooltip2, Popover2 } from "@blueprintjs/popover2";
import { Menu, MenuDivider, MenuItem, Tag, Button } from "@blueprintjs/core";
import { startRegistration } from '@simplewebauthn/browser';

import { EventType } from '@azure/msal-browser';
import {
    LOGIN_REQUEST,
    PUBLIC_CLIENT_APPLICATION,
    TOKEN_REQUEST,
    GRAPH_CONFIG
} from '../../msalConfig'

import API from '../Utilities/API';

export default (props) => {
    return (
        <div style={{ marginLeft: 4, marginTop: 5, position: 'absolute', left: 0, bottom: 100 }}>
            <Popover2
                content={
                    <Menu>
                        <MenuItem
                            text={'Biometric login'}
                            icon={'badge'}
                            onClick={() => {
                                API.StartRegistration().then((response) => {
                                    startRegistration(response.data).then((result) => {
                                        API.VerifyRegistration(result).then((response) => {
                                            if (response.data.success) {
                                                alert('Registration successful!\n\nTry logging in again to see the new device.');
                                            } else {
                                                alert('There has been a problem with your registration. Please try again or contact administrator.');
                                            }
                                        });
                                    })
                                });
                            }}
                        />
                        <MenuItem
                            text={'Azure SSO'}
                            icon={'third-party'}
                            onClick={async () => {
                                const loginResponse = await PUBLIC_CLIENT_APPLICATION.loginPopup(LOGIN_REQUEST);
                                if (loginResponse.account) {
                                    await PUBLIC_CLIENT_APPLICATION.setActiveAccount(loginResponse.account);
                                }
                                const tokenResponse = await PUBLIC_CLIENT_APPLICATION.acquireTokenSilent(TOKEN_REQUEST);
                                API.SaveSAML(tokenResponse).then(() => {
                                    window.askUnload = false;
                                    alert('Your account has been connected to your Entra ID login. You will be logged out now.');
                                    window.location.href = '/logout';
                                })
                            }}
                        />
                    </Menu>
                }
                placement={props.placement || 'right-start'}
            >
                <Button
                    large
                    icon={'lock'}
                />
            </Popover2>
        </div>
    )

}