import React, { useState, useEffect } from 'react';

import { Dialog, Classes, Button, InputGroup, HTMLSelect, Label, Spinner, Switch } from '@blueprintjs/core';

import API from '../Utilities/API';
import SingleResponse from './SingleResponse';
import { render } from '@testing-library/react';

const RenderList = (props) => {
    const list = props.data.response.split('\n');
    const [checked, setChecked] = useState([]);
    const [lastChange, setLastChange] = useState(0);

    useEffect(() => {
        if (props.setQuery) props.setQuery(checked);
    }, [checked])

    return (
        <>
            {
                list.map((entry) => {
                    const tr = entry.trim();
                    const entryData = tr.split(';');
                    if (entryData.length != 2) return null;
                    else return (
                        <div>
                            <Switch
                                key={`Switch-${entryData[0]}-${lastChange}`}
                                label={`${entryData[0]} - ${entryData[1]}`}
                                checked={checked.indexOf(tr) > -1}
                                onChange={(e) => {
                                    let c = checked;
                                    if (c.indexOf(tr) > -1) {
                                        c = c.filter((entry) => (entry != tr));
                                    } else {
                                        c.push(tr)
                                    }
                                    setChecked([...c]);
                                    setLastChange(new Date().getTime());
                                }}
                            />
                        </div>
                    )
                })
            }
        </>
    )
}


export default ({ text, onHide, onSearch }) => {

    const [keyword, setKeyword] = useState(text);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const [response, setResponse] = useState(false);
    const [query, setQuery] = useState('');
    const [terms, setTerms] = useState('');

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            API.AI.ExpandSearch(text).then((response) => {
                if (response && response.data && response.data.Data && response.data.Data.id) {
                    setData(response.data.Data);
                    API.AI.Request(response.data.Data.id).then((res) => {
                        setResponse(res.data.Data[0]);
                    });
                }
            });
        }
    }, [text])

    return (
        <>
            <Dialog
                title={'Expand search'}
                isOpen={true}
                usePortal
                className={'bp5-dark'}
                onClose={() => {
                    onHide && onHide();
                }}
            >
                <div className={Classes.DIALOG_BODY}>
                    {data && response ? (
                        <>
                            <SingleResponse
                                request={data}
                                data={response}
                                closeAI={() => { }}
                                speed={2}
                                render={(data) => (
                                    <>
                                        {
                                            data.process_done && data.process_done.length > 0 ? (
                                                <RenderList
                                                    data={data}
                                                    setQuery={(terms) => {
                                                        setTerms(terms);
                                                        let q = [];
                                                        for (let i = 0; i < terms.length; i++) {
                                                            let x = terms[i].split(';');
                                                            q.push(`("${x[0]}")`);
                                                        }
                                                        setQuery(q.join(' OR '));
                                                    }}
                                                />
                                            ) : (
                                                <Spinner />
                                            )
                                        }
                                    </>
                                )}
                            />
                        </>
                    ) : null}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            intent='success'
                            fill
                            large
                            disabled={query.length === 0}
                            onClick={() => {
                                if (onSearch) onSearch(query);
                                API.RequestKeyword(text, terms.join('||'))
                            }}
                        >
                            Search
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

